<template>
  <div class="NavHeader">
    <div class="box">
      <div class="box-left">
        <img src="@/assets/image/header/header.png" alt="logo">
      </div>
      <div class="box-right">
        <!-- type == 1 硬件檢測 -->
        <div class="box-right-type" v-if="type == 1">
          <img src="@/assets/image/header/type1.png" alt="硬體檢測">
          硬體檢測
        </div>
        <!-- type == 2 提醒事項 -->
        <div class="box-right-type" v-if="type == 2">
          <img src="@/assets/image/header/type2.png" alt="提醒事項">
          提醒事項
        </div>
        <!-- type == 3 聽力測驗 -->
        <div class="box-right-type" v-if="type == 3">
          <img src="@/assets/image/header/type3.png" alt="聽力測驗">
          聽力測驗
        </div>
        <!-- type == 4 閱讀能力測驗 -->
        <div class="box-right-type" v-if="type == 4">
          <img src="@/assets/image/header/type4.png" alt="閱讀能力測驗">
          閱讀能力測驗
        </div>
        <!-- type == 5 寫作能力測驗 -->
        <div class="box-right-type" v-if="type == 5">
          <img src="@/assets/image/header/type5.png" alt="寫作能力測驗">
          寫作能力測驗
        </div>
        <!-- type == 6 口說能力測驗 -->
        <div class="box-right-type" v-if="type == 6">
          <img src="@/assets/image/header/type6.png" alt="口說能力測驗">
          口說能力測驗
        </div>
        <!-- type == 7 休息時間 -->
        <div class="box-right-type" v-if="type == 7">
          <img src="@/assets/image/header/type7.png" alt="休息時間">
          休息時間
        </div>
        <div class="box-right-button" v-if="[3, 4, 5, 6, 7].includes(type) && examinationRoomType != 3" @click="questionHelp">
          考試發生問題！求救請按此
        </div>
      </div>
    </div>

    <!-- 問題回報發起彈窗 -->
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="app-popup question-popup" v-show="popupShow">
        <transition
          enter-active-class="animated fadeInRightBig"
          leave-active-class="animated fadeOutRightBig"
        >
          <div class="question" v-if="popupShow">
            <div class="question-box">
              <h1 class="question-box-h1">
                <img src="@/assets/image/icon/icon-return.png" alt="">
                問題回報
              </h1>
              <template v-if="formShow">
                <div class="question-box-input">
                  <p class="box-input-p">
                    准考證號碼
                  </p>
                  <el-input v-model="admissionTicket" readonly placeholder="請輸入"></el-input>
                </div>
                <div class="question-box-input">
                  <p class="box-input-p">
                    <span>*</span>緊急聯絡電話(手機或市話)
                  </p>
                  <el-input v-model="dataForm.tel" placeholder="請輸入"></el-input>
                </div>
                <div class="question-box-tip">
                  範例：0912-345-678 或 02-2712-3456
                </div>
                <div class="question-box-input">
                  <p class="box-input-p">
                    <span>*</span>問題類型
                  </p>
                  <el-select v-model="dataForm.faq_type" placeholder="請選擇">
                    <el-option
                      v-for="item in problemList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </div>
                <div class="question-box-input">
                  <p class="box-input-p">
                    問題說明
                  </p>
                  <el-input
                    type="textarea"
                    :rows="6"
                    :maxlength="150"
                    resize="none"
                    v-model="dataForm.faq_explain"
                    placeholder="如果可以，請簡述您遇到的問題（限150字）"
                  ></el-input>
                </div>
                <div class="question-box-title">
                  目前考試時間暫停，請勿「關閉頁面」或「按F5重新整理」，並於填寫完畢問題回報表單後，按照指示操作，謝謝。
                </div>
                <button class="question-box-button1" v-loading="submitLoading" @click="formSubmitClick">
                  提交問題
                </button>
                <div class="question-box-button2" @click="formcancel">
                  取消
                </div>
              </template>
              <template v-if="!formShow">
                <h2 class="question-box-h2">等待客服聯繫</h2>
                <div class="question-box-line"></div>
                <div class="question-box-div">
                  <div class="div-top">
                    <div class="div-top-content">
                      <p>已收到您的問題，稍後客服人員將主動致電聯繫。</p>
                      <p>請耐心等候，謝謝。</p>
                    </div>

                    <!-- 取消問題回報彈窗 -->
                    <transition
                      enter-active-class="animated zoomIn"
                      leave-active-class="animated zoomOut"
                    >
                      <div class="div-top-cancel" v-if="cancelShow">
                        <p class="cancel-p">取消問題回報？</p>
                        <div class="cancel-button">
                          <div class="button button1" @click="problemReturnAffirm">是</div>
                          <div class="button button2" @click="problemReturnCancel">取消</div>
                        </div>
                      </div>
                    </transition>

                    <!-- 老師處理中彈窗 -->
                    <transition
                      enter-active-class="animated zoomIn"
                      leave-active-class="animated zoomOut"
                    >
                      <div class="div-top-receive" v-if="receiveShow">
                        <p class="receive-p">老師處理中</p>
                        <p class="receive-p">請耐心等候聯繫</p>
                        <div class="receive-button" @click="receiveShow = false">好</div>
                      </div>
                    </transition>
                  </div>
                  <div class="div-bottom">
                    <div class="div-bottom-p">
                      若等待時間過長/或問題已解決，不需要客服協助處理，請點選下方<span>「取消回報繼續考試」</span>按紐，時間恢復計算。
                    </div>
                    <div class="div-bottom-button" @click="problemReturnOpen">取消問題回報繼續考試</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <!-- 問題回報響應彈窗 -->
    <problem-returns></problem-returns>

    <!-- 分享按钮弹窗 -->
    <share-popup v-model="shareShow"></share-popup>
  </div>
</template>

<script>
import store from '@/store'
import { debounce } from 'lodash';
import { mapGetters } from "vuex"
import { questionFaqsList, questionProblemReturn, questionCancelProblemReturn, questionGetProblemStatus } from "@/request/api"
import ProblemReturns from "@/components/ProblemReturns.vue"
import SharePopup from '@/components/SharePopup.vue'
export default {
  name: "NavHeader",
  components: { ProblemReturns, SharePopup },
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 用户信息
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),

      // 問題回報彈窗
      popupShow: false,
      // 收到問題回報彈窗
      receiveShow: false,
      // 取消問題回報彈窗
      cancelShow: false,
      // 问题表单提交状态
      formShow: true,

      // 問題回報id  存在的時候調用輪詢  不存在的時候不調用
      insert_id: '',
      // 問題列表
      problemList: [],
      // 问题提交表单
      dataForm: {
        tel: '',
        faq_type: '',
        faq_explain: '',
      },
      
      // 輪詢時間  10秒請求一次
      pollingCountdown: 10000,
      // 輪詢定时器
      pollingTimer: null,

      // 弹窗
      shareShow: false,
      submitLoading: false
    }
  },
  computed: {
    ...mapGetters(['admissionTicket', 'shareType', 'examinationRoomType'])
  },
  watch: {
    popupShow(val) {
      if(!val) {
        // 彈窗關閉 關閉定時輪詢請求 清除定時器
        clearInterval(this.pollingTimer);
        this.pollingTimer = null;

        // 彈窗關閉 開始考試倒計時
        store.commit('problem/SET_PROBLEMPOPUPSTATUS', false)

        this.formShow = true
        this.cancelShow = false
        this.receiveShow = false
        this.submitLoading = false
        this.dataForm = {
          tel: '',
          faq_type: '',
          faq_explain: '',
        }
      } else {
        // 彈窗打開 暫停考試倒計時
        store.commit('problem/SET_PROBLEMPOPUPSTATUS', true)
      }
    },
    // 监听agora分享状态 弹出强制分享弹窗
    shareType: {
      handler(val) {
        const whiteList = ['/screenSharing', '/notes', '/halfTime']
        if (whiteList.includes(this.$route.path)) {
          if (val != 1) {
            this.shareShow = true
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    // 获取问题列表
    getProblemList() {
      questionFaqsList().then(res => {
        this.problemList = res.data.list
      })
    },
    // 輪詢請求 每隔n秒執行一次
    pollingCountdownHandle() {
      this.pollingTimer = setInterval(()=>{
        this.getQuestion()
      }, this.pollingCountdown)
    },
    // 輪詢請求問題回報處理
    getQuestion() {
      if (this.insert_id) {
        questionGetProblemStatus({ id: this.insert_id }).then(res => {
          if (res.data.status == 2) { // 0未处理  1处理中  2已处理  3弃单
            this.popupShow = false
            store.commit('problem/SET_PROBLEMRESULTSSTATUS', res.data.results_status)
            store.commit('problem/SET_PROBLEMRESULTS', res.data.results)
            store.commit('problem/SET_PROBLEMSTATUS', 2)
          } else if (res.data.status == 3) { // 取消接单
            this.popupShow = false
            store.commit('problem/SET_PROBLEMSTATUS', 1)
          }
        })
      }
    },

    // 問題求救
    questionHelp() {
      this.getProblemList()
      // this.dataForm.tel = this.userInfo.tel_h || this.userInfo.tel_m
      this.dataForm.tel = this.userInfo.emergency_contact_number
      this.popupShow = true
    },

    // 防抖處理
    // formSubmitClick: debounce(function() {
    //   this.formSubmit()
    // }, 300),
    // 提交問題
    formSubmitClick: debounce(function() {
      // if (this.dataForm.tel == '') {
      //   return this.$message({
      //     message: '聯絡電話不能為空！',
      //     type: 'warning'
      //   })
      // }
      // if (this.dataForm.faq_type == '') {
      //   return this.$message({
      //     message: '問題類型不能為空！',
      //     type: 'warning'
      //   })
      // }
      // if (!this.submitLoading) {
      //   this.submitLoading = true
      //   questionProblemReturn(this.dataForm).then(res => {
      //     this.formShow = false
      //     this.insert_id = res.data.insert_id
      //     // 彈窗打開 開啟定時輪詢請求
      //     this.pollingCountdownHandle()
      //     // this.submitLoading = false
      //   }).catch(() => {
      //     this.submitLoading = false
      //   })
      // }
    }, 500),
    // 取消提交問題
    formcancel() {
      this.popupShow = false
    },

    // 取消問題回報-彈窗打開
    problemReturnOpen() {
      if (this.receiveShow || this.cancelShow) {
        return false
      }
      questionGetProblemStatus({ id: this.insert_id }).then(res => {
        if (res.data.status == 1) {
          this.receiveShow = true
        } else {
          this.cancelShow = true
        }
      })
    },
    // 取消問題回報-確認取消回報
    problemReturnAffirm() {
      questionCancelProblemReturn({ id: this.insert_id }).then(() => {
        this.cancelShow = false
        this.popupShow = false
        store.commit('problem/SET_PROBLEMSTATUS', 1)
      })
    },
    // 取消問題回報-取消
    problemReturnCancel() {
      this.cancelShow = false
    },
  }
}
</script>

<style lang="less" scoped>
.NavHeader {
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  user-select: none;
  z-index: 99;
  .box {
    margin: 0 auto;
    width: 1320px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-left {
      width: 284px;
      height: 45px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-right {
      display: flex;
      align-items: center;
      .box-right-type {
        display: flex;
        align-items: center;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        color: #333333;
        img {
          margin-right: 8px;
          width: 32px;
          height: 32px;
        }
      }
      .box-right-button {
        user-select: none;
        cursor: pointer;
        margin-left: 30px;
        width: 240px;
        height: 45px;
        background: #E2664F;
        border-radius: 22.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Microsoft JhengHei","微軟正黑體";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }
    }
  }
  
  .question-popup {
    .question {
      width: 100%;
      height: 100%;
      position: relative;
      .question-box {
        padding: 16px 12px;
        position: absolute;
        top: 70px;
        right: 56px;
        width: 206px;
        min-height: 760px;
        height: calc(100% - 172px);
        background: #FFFFFF;
        border-radius: 8px;
        .question-box-h1 {
          display: flex;
          align-items: center;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          color: #333333;
          img {
            margin-right: 8px;
            width: 28px;
            height: 23px;
          }
        }
        .question-box-h2 {
          margin: 11px 0 14px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          color: #333333;
        }
        .question-box-line {
          width: 220px;
          height: 1px;
          background: #EAEAEA;
          transform: translateX(-7px);
        }
        .question-box-div {
          height: calc(100% - 85px);
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          .div-top {
            .div-top-content {
              margin: 16px 0;
              display: flex;
              flex-flow: column;
              justify-content: space-between;
              padding: 10px 12px;
              height: 104px;
              background: #F5F7FD;
              border-radius: 0px 8px 8px 8px;
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 21px;
              color: #333333;
            }
            .div-top-receive {
              text-align: center;
              padding: 33px 33px 20px;
              width: calc(100% - 66px);
              border-radius: 12px;
              background-image: url('../assets/image/header/return1.png');
              background-repeat: no-repeat;
              background-size: 100%;
              .receive-p {
                font-family: "Microsoft JhengHei","微軟正黑體";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                text-align: center;
                color: #FFFFFF;
              }
              .receive-button {
                margin: 17px auto 0;
                cursor: pointer;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 36px;
                background: #3EE1A4;
                border-radius: 8px;
                font-family: "Microsoft JhengHei","微軟正黑體";
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 29px;
                color: #FFFFFF;
              }
            }
            .div-top-cancel {
              text-align: center;
              padding: 36px 16px 30px;
              width: calc(100% - 32px);
              border-radius: 12px;
              background-image: url('../assets/image/header/return1.png');
              background-repeat: no-repeat;
              background-size: 100%;
              .cancel-p {
                font-family: "Microsoft JhengHei","微軟正黑體";
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 27px;
                text-align: center;
                color: #FFFFFF;
              }
              .cancel-button {
                margin-top: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .button {
                  cursor: pointer;
                  user-select: none;
                  width: 80px;
                  height: 36px;
                  border-radius: 8px;
                  font-family: "Microsoft JhengHei","微軟正黑體";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 22px;
                  line-height: 29px;
                  color: #FFFFFF;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .button1 {
                  background: #FFC121;
                  border: 1px solid #FFC121;
                }
                .button2 {
                  background: rgba(255, 255, 255, 0.1);
                  border: 1px solid #FFFFFF;
                }
              }
            }
          }
          .div-bottom {
            .div-bottom-p {
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 21px;
              color: #979798;
              span {
                color: #0c4fa2;
              }
            }
            .div-bottom-button {
              cursor: pointer;
              user-select: none;
              margin-top: 40px;
              width: 100%;
              height: 40px;
              background: #989898;
              border-radius: 8px;
              font-family: "Microsoft JhengHei","微軟正黑體";
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: 0.01em;
              color: #FFFFFF;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .question-box-tip {
          padding: 4px 0 6px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 15px;
          display: flex;
          align-items: center;
          color: #979798;
        }
        .question-box-input {
          padding: 14px 0 6px;
          .box-input-p {
            margin-bottom: 4px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #000000;
            span {
              color: #FF0F31;
            }
          }
          /deep/ .el-select {
            width: 100%;
          }
          /deep/ .el-input__inner {
            padding: 10px 15px;
            width: 100%;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #D7D8D8;
            border-radius: 4px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #333333;
          }
          /deep/ .el-input__inner::placeholder {
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #979798;
          }
          /deep/ .el-textarea__inner {
            padding: 11px 15px;
            width: 100%;
            height: 150px;
            background: #FFFFFF;
            border: 1px solid #D7D8D8;
            border-radius: 4px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #333333;
          }
          /deep/ .el-textarea__inner::placeholder {
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            color: #979798;
          }
        }
        .question-box-title {
          margin: 12px 0 28px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #979798;
        }
        .question-box-button1 {
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          background: #0C4FA2;
          border: 1px solid #0C4FA2;
          border-radius: 8px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #FFFFFF;
        }
        .question-box-button2 {
          margin-top: 12px;
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 38px;
          background: #FFFFFF;
          border: 1px solid #989898;
          border-radius: 8px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #989898;
        }
      }
    }
  }
}
</style>